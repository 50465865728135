<template>
  <div class="w-full lg:w-4/5 flex flex-col justify-center items-center pb-24 lg:pb-36">
    <template v-if="isAppLaunched">
      <HomepageSectionTitle center>
        <template v-slot:title1>{{ trans('ABOUT:SubscribeWelcome') }}</template>
      </HomepageSectionTitle>
      <div v-if="userData">
        <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-6">
          <Button
            variant="buttonRedOutlinedLarge"
            class="uppercase"
            :to="{name: 'chats'}"
            :text="trans('BUTTON:GoToChats')"
          />
          <Button
            variant="buttonRedLarge"
            class="uppercase"
            :to="{name: 'search'}"
            :text="trans('BUTTON:GoToNewContacts')"
          />
        </div>
      </div>
      <div v-else class="text-center">
        <div class="mb-6 lg:mb-12 text-lg lg:text-2xl flex flex-col gap-3 lg:gap-6 px-6 lg:px-16">
          <span class="flex flex-col gap-3 lg:gap-6" v-html="trans('ABOUT:SubscribeWelcomeText')"/>
        </div>
        <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-3 lg:gap-6">
          <Button
            variant="buttonRedLarge"
            class="uppercase"
            :to="{name: 'auth-register'}"
            :text="trans('BUTTON:Register')"
          />
          <Button
            variant="buttonRedOutlinedLarge"
            class="uppercase"
            :to="{name: 'auth-login'}"
            :text="trans('BUTTON:LogIn')"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <HomepageSectionTitle center narrow-bottom-margin>
        <template v-slot:title1>{{ trans('ABOUT:Subscribe') }}</template>
      </HomepageSectionTitle>
      <div class="text-center text-lg lg:text-2xl flex flex-col gap-3 lg:gap-6 px-6 lg:px-16"
        v-html="trans('ABOUT:SubscribeText')"
      />
      <div class="w-full flex justify-center py-8 pt-8 lg:pt-12 px-6 lg:px-16">
        <form @submit.prevent="signToNewsletter" class="w-full lg:w-1/2 flex flex-row gap-3 lg:gap-6 items-end "
        >
          <TextField
            variant="subscribe"
            field="email"
            class="w-full border border-grayed rounded"
            custom-class="text-lg lg:text-xl border border-grayed"
            no-error
            :placeholder="trans('ABOUT:SubscribeEmailPlaceholder')"
            v-model="email"
          />
          <Button
            variant="buttonRedLarge"
            class="uppercase"
            btn-type="submit"
            @clicked="signToNewsletter"
            :loading="newsletterLoading"
            :text="trans('BUTTON:Send')"
          />
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import HomepageSectionTitle from "@/views/content/components/Homepage/HomepageSectionTitle";
import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import {isAppLaunched} from "@/common/helpers/utils";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SectionWelcome",
  components: {
    HomepageSectionTitle,
    Button,
    TextField,
  },
  data() {
    return {
      email: null,
      isAppLaunched: isAppLaunched(),
    }
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('newsletter', ['newsletterLoading']),
  },
  methods: {
    ...mapActions('newsletter', ['signToNewsletterRequest']),

    signToNewsletter() {
      this.signToNewsletterRequest({email: this.email})
    },
  },
}
</script>
