<template>
  <div
    class="relative w-full z-30"
    :class="[
      screenHigh?'h-screen':'prlx-img',
      !screenHigh?desktopHeight?'lg:h-'+desktopHeight:'lg:h-800':'',
      noTopMarginDesktop?'lg:mt-0':'lg:mt-32',
      noBottomMarginDesktop?'lg:mb-0':'lg:mb-32',
      noTopMarginMobile?'mt-0':'mt-32',
      noBottomMarginMobile?'mb-0':'mb-32',
    ]"
    data-z-index="1"
  />
</template>
<script>
export default {
  name: "HomepageParallax",
  props: {
    screenHigh:Boolean,
    desktopHeight:Number,
    noTopMarginDesktop:Boolean,
    noBottomMarginDesktop:Boolean,
    noTopMarginMobile:Boolean,
    noBottomMarginMobile:Boolean,
  }
}
</script>

