<template>
  <t-card variant="homepage">
    <div class="w-full lg:w-1/2 px-6 lg:px-16" :class="{'order-2': reverse}">
      <div class="font-helvNeu text-2xl lg:text-5xl leading-normal text-redAccentLight text-center pt-16 pb-8 lg:pt-0 lg:pb-16">
        <slot name="leftTitle"/>
      </div>
      <div class="text-pretty text-lg">
        <slot name="leftText"/>
      </div>
    </div>
    <div class="w-full lg:w-1/2 lg:block" :class="{'order-1': reverse}">
      <slot name="rightContent"/>
    </div>
  </t-card>
</template>

<script>
export default {
  name: "HomepageSection",
  props: {
    reverse: Boolean,
  }
}
</script>
