<template>
  <div class="flex justify-center items-center lg:hover:scale-105 lg:hover:opacity-70 transform ease-out duration-300 cursor-pointer rounded-full"
    :class="[
      bottomMargin?large?'mb-24 lg:mb-36':'mb-12 lg:mb-24':'',
      large?'w-20 h-20 lg:w-24 lg:h-24 mt-24 lg:mt-36':'w-16 h-16 lg:w-20 lg:h-20 mt-12 lg:mt-24',
      dark?'bg-white bg-opacity-60 border border-grayed border-opacity-50':'bg-white bg-opacity-20 border border-white border-opacity-40'
    ]"
    @click="$emit('click')"
  >
    <base-icon :width="checkIsMobile?large?36:24:large?48:36" :height="checkIsMobile?large?36:24:large?48:36"
      color="fill-redAccent" name="chevronDownBig"
    />
  </div>
</template>

<script>
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  name: "HomepageChevron",
  props: {
    bottomMargin: Boolean,
    large:Boolean,
    dark:Boolean
  },
  components: {
    BaseIcon,
  },
  data() {
    return {
      checkIsMobile: checkIsMobileDevice(),
    }
  },
}
</script>